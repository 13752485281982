<template>
  <div ref="main" class="main">
    <van-skeleton :row="6" :loading="loading">
      <van-cell-group>
        <van-cell title="发票名称" :value="applyName" icon="idcard" />
        <van-cell title="公司税号" :value="applyIdentity" v-if="applyIdentity" icon="other-pay" />
        <van-cell title="开票公司" :value="companyName" icon="hotel-o" />
        <van-cell title="开票金额" :value="amount" v-if="invoiceUrl" icon="balance-list-o" />
        <van-cell title="申请时间" :value="applyTime" v-if="status === '等待审核'" icon="clock-o" />
        <van-cell title="审核时间" :value="auditTime" v-if="status === '正在开票' || status === '开红票中'" icon="clock-o" />
        <van-cell title="开票时间" :value="openTime" v-if="status === '开票完成' || status === '已开红票'" icon="clock-o" />
        <van-cell title="开票状态" :value="status" icon="flag-o" />
      </van-cell-group>
    </van-skeleton>
    <canvas style="width: 100%" ref="pdfcanvas" v-show="!loading && invoiceUrl"></canvas>
    <van-empty :description="notice" v-if="loading || !invoiceUrl" :image="url" />
    <div class="btns" v-if="!loading && invoiceUrl">
      <van-button block type="warning" :url="invoiceUrl"> 打开PDF文件 </van-button>
      <van-button block type="primary" @click="open"> 查看发票 </van-button>
    </div>
  </div>
</template>
<script>
import { Field, Cell, CellGroup, Button, Empty, Skeleton, Notify, ImagePreview } from "vant";
import apply from "../service/ApplyService";
import wx from "weixin-js-sdk";
import util from "../util";
import pdfJS from "pdfjs-dist";
import moment from "moment";
import ua from "ua-device";

export default {
  components: {
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton,
    [Notify.Component.name]: Notify.Component,
  },
  data() {
    return {
      invoiceUrl: null,
      applyName: null,
      applyIdentity: null,
      amount: null,
      companyName: null,
      openTime: null,
      applyTime: null,
      auditTime: null,
      status: "正在开票",
      notice: "正在加载...",
      loading: true,
      email: null,
      desktop: false,
      url: "https://img.yzcdn.cn/vant/empty-image-default.png",
    };
  },
  async mounted() {
    let device = new ua(navigator.userAgent);
    if (device.device.type === "desktop") {
      this.$refs.main.style.width = "960px";
      this.desktop = true;
    }
    let key = this.$route.params.key;
    let res = await apply.detail(key);
    if (res.status !== true) {
      this.$router.replace(util.reult(res));
      return;
    }
    let data = res.data;
    this.applyName = data.applyName;
    this.applyIdentity = data.applyIdentity;
    this.amount = util.tc(data.invoiceAmount);
    this.invoiceUrl = data.redInvoiceUrl || data.blueInvoiceUrl;
    this.companyName = data.companyName;
    this.status = data.status;
    this.email = data.email;
    // this.notice = `${this.status}，${this.status === "开票完成" || this.status === "已开红票" ? "正在加载..." : "请关注公众号通知"}`;
    this.applyTime = moment(data.applyTime).format("YYYY-MM-DD HH:mm:ss");
    if (data.auditTime) {
      this.auditTime = moment(data.auditTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (data.openTime) {
      this.openTime = moment(data.openTime).format("YYYY-MM-DD HH:mm:ss");
    }
    apply.wxconfig(data.appId).then(wx.config);
    wx.ready(this.share);
    if (this.invoiceUrl) {
      let pdf = await pdfJS.getDocument({
        url: this.invoiceUrl,
        cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/cmaps/",
        cMapPacked: true,
      });
      let page = await pdf.getPage(1);
      let canvas = this.$refs.pdfcanvas;
      let viewport = page.getViewport(3);
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      let ctx = canvas.getContext("2d");
      let renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      await page.render(renderContext);
    } else {
      this.url = data.appQrcode;
      this.notice = `关注“${data.appName}”，获取开票通知`;
    }
    this.loading = false;
  },
  methods: {
    share() {
      let amountText = this.invoiceUrl ? `开票金额：${this.amount}，` : "";
      wx.updateAppMessageShareData({
        title: this.applyName, // 分享标题
        desc: `好友分享了一张电子发票给您，${amountText}请注意查收。`, // 分享描述
        link: document.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: `${location.origin}/logo.png`, // 分享图标
        success: function () {},
      });
    },
    async send(action) {
      if (action === "confirm") {
        let res = await apply.send(this.$route.params.key, this.email);
        if (res.status) {
          return true;
        } else {
          Notify({ type: "warning", message: res.msg });
          return false;
        }
      }
      return true;
    },
    open() {
      ImagePreview({ images: [this.$refs.pdfcanvas.toDataURL({ format: "image/png", quantity: 1 })], closeable: true, showIndex: false });
    },
  },
};
</script>
<style lang="less">
body {
  margin: 0;
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.main {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fff;
}
.btns {
  display: flex;
  button {
    margin: 10px;
  }
}
</style>
